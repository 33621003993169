<template>
  <div
    class="banner"
    :class="{
      banner_fixed: type === 'fixed',
      banner_sticky: type === 'sticky',
    }"
    @click="handlerAction"
    :style="{top: top}"
  >
    <div class="banner__wrapper">
      <div class="banner__title banner__title_b">
        {{ $t('limitedOffer') }}
      </div>
      <div class="banner__title_mobile_b">
        {{ $t('hurryUp') + ` ${dateUntil}` }}
      </div>
      <div class="banner__sub-title">
        {{ ' ' + $t('getLimitedOffer') }}
      </div>
      <div class="banner__date banner__date_b">
        {{ ` ${$t('validUntil')} ${dateUntil} ` }}
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    type?: 'fixed' | 'relative' | 'sticky';
    top?: string;
  }>(),
  {
    type: 'relative',
  },
);
const emits = defineEmits(['actionHandler']);

const handlerAction = () => {
  emits('actionHandler');
};

const dateUntil = computed(() => {
  const tomorrow = new Date();
  const month = tomorrow.getMonth() + 1;
  tomorrow.setDate(tomorrow.getDate() + 1); // even 32 is acceptable
  return `${tomorrow.getFullYear()}/${
    month > 9 ? month : `0${month}`
  }/${tomorrow.getDate()}`;
});
</script>
<style lang="scss" scoped>
.banner {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  background: var(--var-blue-50);
  padding: 5px;
  min-height: 36px;
  height: fit-content;
  font-size: $fs-16;
  color: var(--var-white-100);
  max-height: 85px;
  overflow: hidden;
  cursor: pointer;
  z-index: 1000;

  &_fixed {
    position: fixed;
  }

  &_sticky {
    position: sticky;
  }

  &__wrapper {
    width: fit-content;
    padding: 0 10px;
  }

  &__title {
    display: inline;

    &_b {
      font-weight: $fw-700;
    }

    @media (max-width: $md) {
      display: none;
    }
    &_mobile {
      display: flex;
      &_b {
        font-weight: $fw-700;
        font-size: $fs-16;
        @media (min-width: $md) {
          display: none;
        }
      }
    }
  }

  &__sub-title {
    display: inline;
    @media (max-width: $md) {
      display: none;
    }
  }

  &__date {
    display: inline;

    &_b {
      font-weight: $fw-700;
    }

    @media (max-width: $md) {
      display: none;
    }
  }

  @media (max-width: $md) {
    font-size: $fs-14;
  }
}
</style>
