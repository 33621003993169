<template>
  <ui-footer v-if="!loc.includes('/profile')" class="footer">
    <div class="container footer__wrapper">
      <div class="section-1">
        <ui-link :to="`/${locale}/offer`" class="logo">
          <DFLogo color="#fff" width="50" height="27" />
          <div>{{ $t(title) }}</div>
        </ui-link>
        <div class="section-1__title">
          {{ $t('quickInteriorVisualizations') }}
        </div>
      </div>
      <div class="section-2" v-if="loc.split('/').length < 4">
        <div class="section-2__title">{{ $t('companyName') }}</div>
        <div class="section-2__links">
          <ModulesOfferNavigationComponent
            special-class="footer-text"
          />
        </div>
      </div>
      <div class="section-3">
        <div class="section-3__title">{{ $t('getInTouch') }}</div>
        <div class="section-3__links">
          <div class="links__email">
            <a
              class="link"
              color="primary"
              itemprop="email"
              style="cursor: pointer"
              href="mailto:support@dreamflat.com"
              >support@dreamflat.com</a
            >
          </div>
          <div class="links__social">
            <SocialMediaLinks :is-need-email="false" />
          </div>
        </div>
      </div>
    </div>
  </ui-footer>
  <div v-if="!loc.includes('/profile')" class="h-item under-footer__wrp">
    <div
      class="container row wrap justify-between items-center content-start q-mt-xl gap-12"
    >
      <p v-if="isDflat" class="text-grey-1 q-ma-none">
        {{ $t('rightsReservedDflat', {endYear: getCurrentYear}) }}
      </p>
      <p v-if="!isDflat" class="text-grey-1 q-ma-none">
        {{ $t('rightsReserved', {endYear: getCurrentYear}) }}
      </p>
      <div
        class="h-item row wrap justify-start items-start content-start"
      >
        <ui-link to="/terms-of-use" class="link link_white q-mr-sm">
          {{ $t('termOfUse') }}
        </ui-link>
        <ui-link to="/privacy-policy" class="link link_white">
          {{ $t('privacyPolicy') }}
        </ui-link>
      </div>
    </div>
  </div>
  <lazy-feed-back-modal
    v-if="isShowFeedBackModal"
    v-model:show="isShowFeedBackModal"
  />
</template>
<script lang="ts" setup>
import {useRoute} from 'vue-router';
import {computed, ref} from 'vue';
import {useI18n} from 'vue-i18n';
import {useRuntimeConfig} from '#imports';

const router = useRoute();
const loc = computed(() => router.path);

const runtimeConfig = useRuntimeConfig();
const isDflat =
  runtimeConfig.public.vueAppBaseUrl.includes('d-flat.ru');
const title = runtimeConfig.public.vueAppTitle;

const isShowFeedBackModal = ref(false);

const {locale} = useI18n();

const getCurrentYear = ref(new Date().getFullYear());

const lang = ref();
function onSelectLang(option: string) {
  lang.value = option;
  locale.value = lang.value;
}
onMounted(() => {
  if (router.query.showFeedBack) {
    isShowFeedBackModal.value = true;
  }
});
</script>
<style lang="scss" scoped>
.container {
  max-width: 1128px !important;
}
.footer {
  color: #fff;
  background: linear-gradient(
      0deg,
      rgba(18, 26, 52, 0.85) 0%,
      rgba(18, 26, 52, 0.85) 100%
    ),
    url($static-url + '/images/mp-main-bg-footer.webp'),
    lightgray 0px -608.357px / 100% 466.824% no-repeat;
  background-position: center;
  padding: $p-10 0;
  position: relative;
  flex: 0 0 auto;

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: $g-16;
    .section-1 {
      grid-column: 1 / 3;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: self-start;

      &__title {
        @include launcher-p3;
        margin-top: $mt-6;
      }
    }
    .section-2 {
      display: flex;
      flex-direction: column;
      grid-column: 3/4;
      justify-content: flex-start;
      align-items: flex-start;
      gap: $g-6;

      &__links {
        display: flex;
        flex-direction: column;
        gap: $g-3;
      }

      &__title {
        @include launcher-h1;
      }
    }
    .section-3 {
      display: flex;
      flex-direction: column;
      grid-column: 4/4;
      gap: $g-6;

      &__links {
        display: flex;
        flex-direction: column;
        gap: $g-3;

        &__email {
        }

        &__social {
        }
      }

      &__title {
        @include launcher-h1;
      }
    }

    @media (max-width: $md) {
      display: flex;
      flex-wrap: wrap;
      .section-1 {
        width: 100%;

        &__title {
          margin-top: $mt-4;
        }
      }
      .section-2 {
        width: 100%;
        margin-top: $mt-5;
        gap: $g-3;
      }

      .section-3 {
        width: 100%;
        margin-top: $mt-5;
        gap: $g-3;
      }
    }
  }
  .logo {
    color: #fff !important;
    padding: 0;

    .q-btn__content {
      display: flex;
      flex-direction: column;
    }
  }

  .footer-list-info {
    margin-right: 24px;
    width: 264px;
  }

  .footer-buttons-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    .footer-lang {
      display: flex;
      flex-direction: row;
      position: absolute;
      top: 110px;
      gap: $g-3;
      justify-content: space-around;
      align-items: center;
      width: 103px;

      .q-icon {
        font-size: 17px;
      }

      .footer-lang-toolbar {
        font-size: 15px;
        flex: none;
      }
    }
  }

  .right {
    .h-lang {
      .q-icon {
        font-size: 18px;
      }
    }

    .lang-btn-groupe-f {
      width: 120px;
      background: $primary;
      border-radius: 8px;
      position: relative;
      text-align: center;

      &::after {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        border: 10px solid transparent;
        /* Прозрачные границы */
        border-bottom: 10px solid #2491e7;
        /* Добавляем треугольник */
        right: 50px;
        top: -20px;
      }

      .q-btn {
        width: 110px;
        margin: 0 auto;
        padding: 6px 12px;
        color: #fff;
        border: none;
        border-radius: 4px;
      }
    }
  }
}
.under-footer__wrp {
  background-color: var(--var-black-000);
  padding: $p-3;
}

@media (max-width: $lg) {
  .footer {
    // .list__item {
    //   //width: 168px!important;
    // }
    .footer-lang {
      display: none;
    }
    .footer-buttons-wrap {
      display: flex;
      flex-direction: column;
      .footer-lang {
        align-items: center;
        display: flex;
        flex-direction: row;
        gap: 8px;
        justify-content: space-around;
        position: inherit;
        top: 110px;
      }
    }
  }
  .under-footer__wrp {
    display: block;
    .under-footer__element {
      padding: 0 16px;
    }
  }
  .h-item {
    display: flex !important;
  }
  .under-footer__wrp.hide {
    display: none !important;
  }
}

@media (max-width: 750px) {
  .footer {
    .list__item {
      width: 179px !important;
    }
    .footer-lang {
      display: none;
    }
  }
  .gap-12 {
    gap: 12px;
  }
}

.link_white {
  color: var(--var-white-400);
}
</style>
